import DalgaAlt from "../comps/DalgaAlt"

import { motion } from "framer-motion"

function AnaSayfa(){


    return(
        <>
        <div
        className="sayfaIciText"
        style={{
            position: 'fixed',
            top: 0,
            left: 0,
            
            width: '100vw',
            height: '100vh',
            backgroundColor: 'rgba(255,236,223,1)',

            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',

            zIndex: 1,

        }}
        >
            <div
                className="container-fluid"
            >
                <div
                    className="row"
                >

                    <div
                        className="col-12"
                        style={{

                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            // backgroundColor: 'yellowgreen',
                        }}
                    >
                        <motion.div
                            initial={{ scale: 1, backgroundColor: 'rgba(255,253,246,0.5)', }}
                            whileHover={{ scale: 1.05, backgroundColor: 'rgba(255,253,246,1)'}}

                            onClick={ () => window.open("https://ikys.titizagro.com.tr/Basvuru", "_blank")}
                            className="yatayDikeyOrtala"
                            style={{
                                borderStyle: 'solid',
                                borderWidth: '2px',
                                borderColor: 'rgba(255,198,198,1)',
                                borderRadius: '25px',

                                backgroundColor: 'rgba(255,253,246,0.5)',
                                width: '30vw',
                                height: '110px',
                                minWidth: '300px',
                                minHeight: '100px',
                            }}
                        >
                            <div
                                className="container-fluid"
                            >
                                <div
                                    className="row"
                                >
                                    <div
                                        className="col-12 yatayDikeyOrtala"
                                        style={{
                                            // backgroundColor: 'yellow',
                                            textAlign: 'center',
                                        }}
                                    >
                                        <span
                                            style={{
                                                // fontWeight: 'bold',
                                                fontSize: '20px',
                                            }}
                                        >
                                            Genel İş Başvuru Formu
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </motion.div>
                    </div>
                    
                    <div
                        className="col-12 pt-3"
                        style={{

                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            // backgroundColor: 'yellowgreen',
                        }}
                    >
                        <motion.div
                            initial={{ scale: 1, backgroundColor: 'rgba(255,253,246,0.5)', }}
                            whileHover={{ scale: 1.05, backgroundColor: 'rgba(255,253,246,1)'}}

                            onClick={ () => window.open("https://forms.gle/cixC61b1jMeVbCu28", "_blank")}
                            className="yatayDikeyOrtala"
                            style={{
                                borderStyle: 'solid',
                                borderWidth: '2px',
                                borderColor: 'rgba(255,198,198,1)',
                                borderRadius: '25px',

                                backgroundColor: 'rgba(255,253,246,0.5)',

                                width: '30vw',
                                height: '110px',
                                minWidth: '300px',
                                minHeight: '100px',
                            }}
                        >
                            <div
                                className="container-fluid"
                            >
                                <div
                                    className="row"
                                >
                                    <div
                                        className="col-12 yatayDikeyOrtala"
                                        style={{
                                            // backgroundColor: 'yellow',
                                            textAlign: 'center',
                                        }}
                                    >
                                        <span
                                            style={{
                                                // fontWeight: 'bold',
                                                fontSize: '20px',
                                            }}
                                        >
                                            2024 yılı Staj Programlarına Başvuru Formu
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </motion.div>
                    </div>
                    
                    <div
                        className="col-12 pt-3"
                        style={{

                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            // backgroundColor: 'yellowgreen',
                        }}
                    >
                        <motion.div
                            initial={{ scale: 1, backgroundColor: 'rgba(255,253,246,0.5)', }}
                            whileHover={{ scale: 1.05, backgroundColor: 'rgba(255,253,246,1)'}}

                            onClick={ () => window.open("https://docs.google.com/forms/d/e/1FAIpQLSeV_OuQmg0dxukZ-VSrGm_T9LGDoci-a_ri859y5L9gUU04iw/viewform", "_blank")}
                            className="yatayDikeyOrtala"
                            style={{
                                borderStyle: 'solid',
                                borderWidth: '2px',
                                borderColor: 'rgba(255,198,198,1)',
                                borderRadius: '25px',

                                backgroundColor: 'rgba(255,253,246,0.5)',

                                width: '30vw',
                                height: '110px',
                                minWidth: '300px',
                                minHeight: '100px',
                            }}
                        >
                            <div
                                className="container-fluid"
                            >
                                <div
                                    className="row"
                                >
                                    <div
                                        className="col-12 yatayDikeyOrtala"
                                        style={{
                                            // backgroundColor: 'yellow',
                                            textAlign: 'center',
                                        }}
                                    >
                                        <span
                                            style={{
                                                // fontWeight: 'bold',
                                                fontSize: '20px',
                                            }}
                                        >
                                            Kişi Tanıma Uygulamaları
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </motion.div>
                    </div>
                    
                </div>
            </div>
        </div>
        <DalgaAlt />
        </>
    )
} export default AnaSayfa