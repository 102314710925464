
import './App.css';

import { BrowserRouter as Router } from 'react-router-dom';
import { Routes ,Route } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import DalgaAlt from './comps/DalgaAlt';
import { Suspense } from 'react';
import AnaSayfa from './pages/AnaSayfa';
import DortYuzDort from './pages/404';

function App() {
  return (
    <Router basename='/'>
      <Suspense
        fallback={
          <>
          <div
            style={{
              width: '100vw',
              height: '100vh',

              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',

              backgroundColor: 'rgba(255,236,223,1)',
            }}
          >
              <span
                style={{
                  color: 'black',
                  fontWeight: 'bold',
                  fontSize: '20px',
                }}
              >
                Yükleniyor...
              </span>
          </div>
          </>
        }
      >
        <Routes>
          <Route path='/' element={<AnaSayfa />} />
          <Route path='*' element={<DortYuzDort />} />
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;
