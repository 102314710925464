

import './LoadingIcerik.css';

import { motion } from 'framer-motion';

function LoadingIcerik(){
    
    const animasyon1 = {
        open: { opacity: 1, scale: 1 },
        closed: { opacity: 0, scale: 0 },
    };


    return(
        <>
        <motion.div
            initial="closed"
            animate="open"
            exit="closed"
            variants={animasyon1}
        
            className="container-fluid"
            style={{
                // position: 'fixed',
                // top: 0,
                // left: 0,
                // width: '100vw',
                // height: '100vh',
                // backgroundColor: 'rgba(0,0,0,0.7)',
                // overflowX: 'hidden',
                // overflowY: 'hidden',
                // color: 'white',
                // fontSize: '30px',
                // fontWeight: 'bold',
                // zIndex: 1000,

                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <div
                className="row"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <div
                    className="col-12"
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    {/* <span>Yükleniyor...</span> */}
                    <section
                        className='app-loaderIcerik'
                    >
                        <div
                            className='bouncing-loaderIcerik'
                        >
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </section>
                </div>
            </div>
        </motion.div>
        </>
    )
} export default LoadingIcerik
